import * as t from 'io-ts';
import * as c from '../constants';
import { Address } from './address';
import optionalToUndefined from '../t/optionalToUndefined';
import { keys, omit, pick } from 'ramda';

export const ProxyAddressSource = t.union([t.literal(c.DONATED), t.literal(c.PARTNER), t.literal(c.COUNCIL)]);

export type ProxyAddressSource = t.TypeOf<typeof ProxyAddressSource>;

export const TProxyAddress = t.type({
    proxyAddressUuid: t.string,
    modifiedAt: t.string,
    createdAt: t.string,
    councilAreaUuid: t.string,
    source: ProxyAddressSource,
    isActive: t.boolean,
    activationDate: optionalToUndefined(t.string),
    expiryDate: t.string,
    orgUuids: optionalToUndefined(t.array(t.string)),
});

export const PProxyAddress = t.partial({
    __typename: t.literal('ProxyAddress'),
});

export const ProxyAddress = t.intersection([TProxyAddress, PProxyAddress, Address]);

export type ProxyAddress = t.TypeOf<typeof ProxyAddress>;

export const ProxyAddressOptions = t.type({
    ...omit([...keys(Address.props), 'proxyAddressUuid', 'createdAt', 'modifiedAt'], TProxyAddress.props),
});

export type ProxyAddressOptions = t.TypeOf<typeof ProxyAddressOptions>;

export const ProxyAddressOptionsInput = t.type({
    ...omit(['expiryDate', 'activationDate'], ProxyAddressOptions.props),
    expiryDate: t.type({
        expiryDay: t.string,
        expiryMonth: t.string,
        expiryYear: t.string,
    }),
    activationDate: t.type({
        activationDay: t.string,
        activationMonth: t.string,
        activationYear: t.string,
    }),
});

export type ProxyAddressOptionsInput = t.TypeOf<typeof ProxyAddressOptionsInput>;

export const CreateProxyAddressArgs = t.type({
    ...omit(['createdAt', 'modifiedAt', 'proxyAddressUuid'], TProxyAddress.props),
    ...Address.props,
});

export type CreateProxyAddressArgs = t.TypeOf<typeof CreateProxyAddressArgs>;

export const UpdateProxyAddressArgs = t.type(
    pick(
        ['proxyAddressUuid', 'councilAreaUuid', 'isActive', 'activationDate', 'expiryDate', 'source'],
        TProxyAddress.props,
    ),
);

export type UpdateProxyAddressArgs = t.TypeOf<typeof UpdateProxyAddressArgs>;
