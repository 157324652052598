import { Address, ProxyAddressOptionsInput, ProxyAddressSource } from 'proxyaddress-common/types';
import { isEmpty, values } from 'ramda';
import React, { FormEvent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { createProxyAddress } from '../../graphql/proxyAddress';
import { addressInitialState } from '../../utils/addresses';
import {
    formatProxyAddress,
    initialProxyAddressOptions,
    ProxyAddressErrors,
    proxyAddressOptionsInitialErrors,
    proxyAddressSourceOptions,
    validateProxyAddressInput,
} from '../../utils/proxyAddress';
import Button from '../bits/Buttons/Button';
import Caption from '../bits/Caption/Caption';
import AddressForm from '../bits/Form/AddressForm';
import Form from '../bits/Form/Form';
import Label from '../bits/Form/Label';
import DateInputField, { DateInputsWrapper } from '../bits/FormFields/Dates';
import FormField from '../bits/FormFields/FormField';
import SearchSelectInput, { InputOption } from '../bits/FormFields/SearchSelectInput';
import SelectInput, { InputOptions } from '../bits/FormFields/SelectInput';
import { AdminStateContext } from '../WithAdminState/adminState';
import { getRouteOrganisationDetail, ROUTE_ADDRESSES } from '../../utils/routes';
import AuthContext from '../WithAuth/AuthContext';
import { OrgDetailTabs } from '../templates/OrganisationDetails';
import { useParams } from 'react-router-dom';

const CreateProxyAddress = () => {
    const history = useHistory();
    const { orgUuid, councilAreaUuid }: { orgUuid: string; councilAreaUuid: string } = useParams();
    const { isAdmin } = useContext(AuthContext);
    const [address, setAddress] = useState<Address>(addressInitialState);
    const [proxyAddressOptions, setProxyAddressOptions] =
        useState<ProxyAddressOptionsInput>(initialProxyAddressOptions);
    const [errors, setErrors] = useState<ProxyAddressErrors>(proxyAddressOptionsInitialErrors);
    const [createProxyAddressMutation, { loading: proxyAddressLoading, error: proxyAddressError }] =
        createProxyAddress.hook();
    const { councilAreas } = useContext(AdminStateContext);
    const [councilAreaOptions, setCouncilAreaOptions] = useState<InputOptions[]>([]);
    const backLink = isAdmin
        ? `${getRouteOrganisationDetail(orgUuid)}?tab=${OrgDetailTabs.addresses}`
        : ROUTE_ADDRESSES;

    useEffect(() => {
        const councilAreaOptionsMap = values(councilAreas).map((area) => {
            return { value: area.councilAreaUuid, label: area.name };
        });

        setCouncilAreaOptions([{ value: '', label: '-' }, ...councilAreaOptionsMap]);
    }, [councilAreas]);

    useEffect(() => {
        if (councilAreaUuid) {
            setProxyAddressOptions({
                ...proxyAddressOptions,
                councilAreaUuid,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [councilAreaUuid]);

    useEffect(() => {
        if (!proxyAddressOptions.isActive) {
            setProxyAddressOptions({
                ...proxyAddressOptions,
                activationDate: { ...initialProxyAddressOptions.activationDate },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proxyAddressOptions.isActive]);

    const addAddress = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            const validationResult = validateProxyAddressInput(address, proxyAddressOptions);

            if (values(validationResult).every(isEmpty)) {
                const addressToCreate = formatProxyAddress(address, proxyAddressOptions);

                await createProxyAddressMutation({
                    variables: { proxyAddress: addressToCreate },
                    refetchQueries: ['listProxyAddresses', 'listProxyAddressesByOrg'],
                });
                history.push(backLink);
            } else {
                setErrors(validationResult);
            }
        } catch (error) {
            setErrors({
                ...errors,
                incorrectFormError: 'Something went wrong. Please ensure you have filled in all required fields.',
            });
        }
    };

    const formBody = (
        <>
            <AddressForm address={address} setAddress={setAddress} errors={errors} disableManualInput />
            {!councilAreaUuid && (
                <SearchSelectInput
                    label="Council area"
                    id="councilArea"
                    options={councilAreaOptions}
                    onChange={(input) => {
                        if (!input) {
                            setProxyAddressOptions({
                                ...proxyAddressOptions,
                                councilAreaUuid: '',
                            });
                        }
                        const councilArea = input as InputOption;
                        setProxyAddressOptions({
                            ...proxyAddressOptions,
                            councilAreaUuid: councilArea.value,
                        });
                    }}
                    error={errors?.councilAreaError}
                    isMulti={false}
                    isClearable={false}
                />
            )}

            <SelectInput
                label="Address source"
                id="source"
                options={proxyAddressSourceOptions}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setProxyAddressOptions({ ...proxyAddressOptions, source: e.target.value as ProxyAddressSource })
                }
            />
            <FormField error={errors.expiryDateError}>
                <Label>Expiry date</Label>
                <DateInputsWrapper>
                    <DateInputField
                        id="expiryDay"
                        type="text"
                        value={proxyAddressOptions.expiryDate.expiryDay}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setProxyAddressOptions({
                                ...proxyAddressOptions,
                                expiryDate: {
                                    ...proxyAddressOptions.expiryDate,
                                    expiryDay: e.target.value,
                                },
                            })
                        }
                        preText="Day"
                        size="small"
                    />

                    <DateInputField
                        id="expiryMonth"
                        type="text"
                        value={proxyAddressOptions.expiryDate.expiryMonth}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setProxyAddressOptions({
                                ...proxyAddressOptions,
                                expiryDate: {
                                    ...proxyAddressOptions.expiryDate,
                                    expiryMonth: e.target.value,
                                },
                            })
                        }
                        preText="Month"
                        size="small"
                    />

                    <DateInputField
                        id="expiryYears"
                        type="text"
                        value={proxyAddressOptions.expiryDate.expiryYear}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setProxyAddressOptions({
                                ...proxyAddressOptions,
                                expiryDate: {
                                    ...proxyAddressOptions.expiryDate,
                                    expiryYear: e.target.value,
                                },
                            })
                        }
                        preText="Year"
                    />
                </DateInputsWrapper>
                <Caption>For example, 3 11 2024</Caption>
                <Caption>If left blank, expiry date will default to a year from today</Caption>
            </FormField>
            <FormField error={errors.activationDateError}>
                <Label>Activation date</Label>
                <DateInputsWrapper>
                    <DateInputField
                        id="activationDay"
                        type="text"
                        value={proxyAddressOptions.activationDate.activationDay}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setProxyAddressOptions({
                                ...proxyAddressOptions,
                                activationDate: {
                                    ...proxyAddressOptions.activationDate,
                                    activationDay: e.target.value,
                                },
                            })
                        }
                        preText="Day"
                        size="small"
                        disabled={!proxyAddressOptions.isActive}
                    />

                    <DateInputField
                        id="activationMonth"
                        type="text"
                        value={proxyAddressOptions.activationDate.activationMonth}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setProxyAddressOptions({
                                ...proxyAddressOptions,
                                activationDate: {
                                    ...proxyAddressOptions.activationDate,
                                    activationMonth: e.target.value,
                                },
                            })
                        }
                        preText="Month"
                        size="small"
                        disabled={!proxyAddressOptions.isActive}
                    />

                    <DateInputField
                        id="activationYear"
                        type="text"
                        value={proxyAddressOptions.activationDate.activationYear}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setProxyAddressOptions({
                                ...proxyAddressOptions,
                                activationDate: {
                                    ...proxyAddressOptions.activationDate,
                                    activationYear: e.target.value,
                                },
                            })
                        }
                        preText="Year"
                        disabled={!proxyAddressOptions.isActive}
                    />
                </DateInputsWrapper>
                <Caption>For example, 3 11 2024</Caption>
                <Caption>If left blank, activation date will default to today's date</Caption>
            </FormField>

            <Button buttonStyle="primary" disabled={proxyAddressLoading} type="submit" text="Save" />
        </>
    );

    return (
        <Form
            title="Add new ProxyAddress"
            cancelTitle="Cancel and discard changes"
            description="Use this form to add a new ProxyAddress"
            onCancel={() => history.push(backLink)}
            body={formBody}
            onSubmit={(e) => addAddress(e)}
            loading={proxyAddressLoading}
            error={!!proxyAddressError}
        />
    );
};

export default CreateProxyAddress;
