import React, { useContext } from 'react';
import { StaffUserContext } from '../../WithStaffUser/staffContext';
import { filterActiveProxyAddresses, makeProxyAddressOptions } from '../../../utils/proxyAddress';
import SelectInput from '../../bits/FormFields/SelectInput';
import { AdminStateContext } from '../../WithAdminState/adminState';

interface AssignProxyAddressProps {
    assignedProxyAddressUuid?: string;
    setAssignedProxyAddressUuid: (arg: string) => void;
    includeRemoveOption?: boolean;
}

const AssignProxyAddress = ({
    assignedProxyAddressUuid,
    setAssignedProxyAddressUuid,
    includeRemoveOption = false,
}: AssignProxyAddressProps) => {
    const { proxyAddresses: adminProxyAddresses } = useContext(AdminStateContext);
    const { proxyAddresses: staffProxyAddresses } = useContext(StaffUserContext);
    const options = makeProxyAddressOptions(filterActiveProxyAddresses(adminProxyAddresses || staffProxyAddresses));
    const removeOption = { value: '', label: 'Remove ProxyAddress' };

    return (
        <SelectInput
            placeholder="Select a ProxyAddress"
            options={includeRemoveOption ? [removeOption, ...options] : options}
            defaultValue={assignedProxyAddressUuid}
            onChange={(e) => setAssignedProxyAddressUuid(e.target.value)}
            id="assignedProxyAddress"
            label="Assign a ProxyAddress:"
        />
    );
};

export default AssignProxyAddress;
