import { APPLICATION_DECISION, APPROVED } from 'proxyaddress-common/constants';
import {
    ActivityLogInput,
    Applicant,
    ApplicantWithStaffDetails,
    SubmitApplicationDecisionArgs,
} from 'proxyaddress-common/types';
import { defaultActivityLogDetails } from 'proxyaddress-common/types/utils';
import React, { ReactElement, useContext, useState } from 'react';
import { submitApplicationDecision } from '../../../graphql/application';
import { StaffUserContext } from '../../WithStaffUser/staffContext';
import ApprovalComplete from './ApprovalComplete';
import ApprovalSubmit from './ApprovalSubmit';
import Loading from '../../bits/Loading';
import ErrorMessage from '../../bits/ErrorMessage/ErrorMessage';
import Modal from '../../bits/Modal/Modal';
import { AdminStateContext } from '../../WithAdminState/adminState';
import { ADMIN_USER_NAME } from '../../../utils/constants';

interface ApproveApplicationModalProps {
    open: boolean;
    setOpen: (arg: boolean) => void;
    applicant: ApplicantWithStaffDetails;
}

const ApproveApplicationModal = ({ open, setOpen, applicant }: ApproveApplicationModalProps): ReactElement => {
    const [submitApplicationDecisionMutation, { loading, error }] = submitApplicationDecision.hook();
    const { proxyAddresses: adminProxyAddresses } = useContext(AdminStateContext);
    const { staffUser, proxyAddresses: staffProxyAddresses } = useContext(StaffUserContext);
    const [showComplete, setShowComplete] = useState(false);
    const [updatedApplicant, setUpdatedApplicant] = useState<Applicant | null>();
    const [assignedProxyAddressUuid, setAssignedProxyAddressUuid] = useState<string | undefined>(
        applicant?.proxyAddressUuid,
    );
    const proxyAddresses = adminProxyAddresses || staffProxyAddresses;

    const approveApplication = async () => {
        const assignedProxyAddress = assignedProxyAddressUuid && proxyAddresses[assignedProxyAddressUuid];

        if (applicant.application && assignedProxyAddress) {
            const activityLogEntry: ActivityLogInput = {
                applicantUuid: applicant.applicantUuid,
                orgUuid: applicant.orgUuid,
                createdBy: staffUser?.staffUuid || ADMIN_USER_NAME,
                logType: APPLICATION_DECISION,
                details: {
                    ...defaultActivityLogDetails,
                    applicationUuid: applicant.application.applicationUuid,
                    note: 'Approved',
                },
            };

            const variables: SubmitApplicationDecisionArgs = {
                closingDecision: APPROVED,
                proxyAddressUuid: assignedProxyAddressUuid,
                councilAreaUuid: assignedProxyAddress?.councilAreaUuid,
                activityLogEntry,
            };

            const updatedApplicant = await submitApplicationDecisionMutation({
                variables,
                refetchQueries: ['listApplicantsByOrg'],
            });
            if (updatedApplicant.data) {
                setUpdatedApplicant(updatedApplicant.data.submitApplicationDecision);
                setShowComplete(true);
            }
        }
    };

    const renderContent = () => {
        if (loading) {
            return <Loading />;
        } else if (showComplete) {
            return <ApprovalComplete setOpen={setOpen} applicant={updatedApplicant as Applicant} />;
        } else {
            return (
                <ApprovalSubmit
                    setOpen={setOpen}
                    applicant={applicant}
                    approveApplication={approveApplication}
                    assignedProxyAddressUuid={assignedProxyAddressUuid}
                    setAssignedProxyAddressUuid={setAssignedProxyAddressUuid}
                />
            );
        }
    };

    return (
        <Modal open={open} setOpen={setOpen}>
            <>
                {renderContent()}
                {error && <ErrorMessage>{error.message}</ErrorMessage>}
            </>
        </Modal>
    );
};

export default ApproveApplicationModal;
