import addYears from 'date-fns/addYears';
import format from 'date-fns/format';
import { isRight } from 'fp-ts/lib/Either';
import { PathReporter } from 'io-ts/lib/PathReporter';
import {
    CreateProxyAddressArgs,
    ProxyAddressOptionsInput,
    Address,
    ProxyAddress,
    ProxyAddressSource,
} from 'proxyaddress-common/types';
import { isEmpty, values, all } from 'ramda';
import * as c from './constants';
import { validateDate } from './application';
import { DONATED, PARTNER, COUNCIL } from 'proxyaddress-common/constants';
import { InputOptions } from '../components/bits/FormFields/SelectInput';

export const initialProxyAddressOptions: ProxyAddressOptionsInput = {
    isActive: true,
    source: 'DONATED',
    expiryDate: { expiryDay: '', expiryMonth: '', expiryYear: '' },
    orgUuids: [],
    councilAreaUuid: '',
    activationDate: { activationDay: '', activationMonth: '', activationYear: '' },
};

export interface ProxyAddressErrors {
    expiryDateError: string;
    postcodeError: string;
    townError: string;
    activationDateError: string;
    councilAreaError: string;
    incorrectFormError: string;
    stateError?: string;
}

export const proxyAddressOptionsInitialErrors: ProxyAddressErrors = {
    expiryDateError: '',
    postcodeError: '',
    townError: '',
    activationDateError: '',
    councilAreaError: '',
    incorrectFormError: '',
};

export const checkDateInput = (day: string, month: string, year: string): boolean => {
    const isEmptyDate = [day, month, year].every((i) => isEmpty(i));
    const isCompleteDate = !isEmptyDate ? [day, month, year].every((i) => !isEmpty(i)) : true;
    const isDateValid = !isEmptyDate ? !isEmpty(validateDate(day, month, year)) : true;

    return isEmptyDate || (isCompleteDate && isDateValid);
};

export const validateProxyAddressInput = (
    address: Address,
    proxyAddressOptions: ProxyAddressOptionsInput,
): ProxyAddressErrors => {
    const { town, postcode } = address;
    const {
        councilAreaUuid,
        expiryDate: { expiryDay, expiryMonth, expiryYear },
        activationDate: { activationDay, activationMonth, activationYear },
    } = proxyAddressOptions;

    const isExpiryDateValid = checkDateInput(expiryDay, expiryMonth, expiryYear);
    const isActivationDateValid = checkDateInput(activationDay, activationMonth, activationYear);

    return {
        postcodeError: isEmpty(postcode) ? c.ERROR_NO_POSTCODE : '',
        townError: isEmpty(town) ? c.ERROR_NO_TOWN : '',
        expiryDateError: !isExpiryDateValid ? c.ERROR_DATE : '',
        activationDateError: !isActivationDateValid ? c.ERROR_DATE : '',
        councilAreaError: isEmpty(councilAreaUuid) ? c.ERROR_NO_COUNCIL_AREA : '',
        incorrectFormError: '',
    };
};

interface ExpiryDate {
    expiryDay: string;
    expiryMonth: string;
    expiryYear: string;
}

interface ActivationDate {
    activationDay: string;
    activationMonth: string;
    activationYear: string;
}

const checkIsDateEmpty = (date: ExpiryDate | ActivationDate): boolean => {
    return all(isEmpty)(values(date));
};

const getDefaultExpiryDate = (): string => {
    const date = addYears(new Date(), 1);
    return format(date, 'yyyy-MM-dd');
};

export const getDefaultActivationDate = (): string => {
    const date = new Date();
    return format(date, 'yyyy-MM-dd');
};

const getExpiryDate = (expiryDate: ExpiryDate): string => {
    const isEmptyDate = checkIsDateEmpty(expiryDate);

    if (isEmptyDate) return getDefaultExpiryDate();
    else {
        const { expiryDay, expiryMonth, expiryYear } = expiryDate;
        return validateDate(expiryDay, expiryMonth, expiryYear);
    }
};

const getActivationDate = (activationDate: ActivationDate): string => {
    const isEmptyDate = checkIsDateEmpty(activationDate);

    if (isEmptyDate) return getDefaultActivationDate();
    else {
        const { activationDay, activationMonth, activationYear } = activationDate;
        return validateDate(activationDay, activationMonth, activationYear);
    }
};

export const formatProxyAddress = (
    address: Address,
    proxyAddressOptions: ProxyAddressOptionsInput,
): CreateProxyAddressArgs => {
    const { isActive } = proxyAddressOptions;
    const expiryDate = getExpiryDate(proxyAddressOptions.expiryDate);
    const activationDate = isActive ? getActivationDate(proxyAddressOptions.activationDate) : undefined;

    const addressToValidate = {
        ...address,
        ...proxyAddressOptions,
        expiryDate,
        activationDate,
    };

    const maybeProxyAddress = CreateProxyAddressArgs.decode(addressToValidate);
    if (!isRight(maybeProxyAddress)) {
        console.log(PathReporter.report(maybeProxyAddress).join('\n\n'));
        throw new Error('ProxyAddress is wrong shape');
    }

    return maybeProxyAddress.right;
};

export const formatProxyAddressSource = (word: string): string => {
    const firstLetter = word.charAt(0).toUpperCase();
    return firstLetter + word.slice(1).toLowerCase();
};

export const formatProxyAddressDates = (dateString: string): string => {
    if (isEmpty(dateString)) {
        return '-';
    }

    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy');
};

export const getProxyAddressDetailsTitle = (proxyAddress: ProxyAddress): string => {
    const { houseName, houseNumber, streetName, town } = proxyAddress;

    return `${houseName && houseName + ', '}${houseNumber && houseNumber + ' '}${
        streetName && streetName + ', '
    }${town}`;
};

export interface EditProxyAddressDetailsErrors {
    expiryDateError: string;
}

export const editProxyAddressDetailsInitialErrors: EditProxyAddressDetailsErrors = {
    expiryDateError: '',
};

export interface ProxyAddressEditDetails {
    expiryDate: ExpiryDate;
    source: ProxyAddressSource;
    isActive: boolean;
}

export const getEditProxyAddressDetails = (proxyAddress: ProxyAddress): ProxyAddressEditDetails => {
    const { expiryDate, isActive, source } = proxyAddress;
    const [expiryYear, expiryMonth, expiryDay] = expiryDate.split('-');

    return { expiryDate: { expiryDay, expiryMonth, expiryYear }, isActive, source };
};

export const proxyAddressSourceOptions: InputOptions[] = [
    { label: 'Donated', value: DONATED },
    { label: 'Council', value: COUNCIL },
    { label: 'Partner', value: PARTNER },
];

export const proxyAddressActiveStatusOptions: InputOptions[] = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
];

export const validateEditProxyAddressDetailsInput = (expiryDate: ExpiryDate): EditProxyAddressDetailsErrors => {
    const { expiryDay, expiryMonth, expiryYear } = expiryDate;
    const validDate = validateDate(expiryDay, expiryMonth, expiryYear);

    return {
        expiryDateError: isEmpty(validDate) ? c.ERROR_DATE : '',
    };
};

const getEditedProxyAddressActivationDate = (
    isActiveNew: boolean,
    isActiveInitial: boolean,
    originalActivationDate: string | undefined,
): string | undefined => {
    const isActiveChanged = isActiveInitial !== isActiveNew;

    if (isActiveChanged && isActiveNew) return getDefaultActivationDate();
    if (isActiveChanged && !isActiveNew) return '';

    return originalActivationDate;
};

interface FormatEditedProxyAddressProps {
    proxyAddress: ProxyAddress;
    details: ProxyAddressEditDetails;
    isActiveInitial: boolean;
}

export const formatEditedProxyAddress = ({ proxyAddress, details, isActiveInitial }: FormatEditedProxyAddressProps) => {
    const { expiryDate, source, isActive } = details;
    const { proxyAddressUuid, councilAreaUuid } = proxyAddress;

    const activationDate = getEditedProxyAddressActivationDate(
        details.isActive,
        isActiveInitial,
        proxyAddress.activationDate,
    );
    return {
        proxyAddressUuid,
        councilAreaUuid,
        source,
        isActive,
        activationDate,
        expiryDate: getExpiryDate(expiryDate),
    };
};

export const filterActiveProxyAddresses = (proxyAddresses: Record<string, ProxyAddress>) =>
    Object.values(proxyAddresses).filter((proxyAddress) => {
        const isActive = proxyAddress.isActive === true;
        const isNotExpired = new Date(proxyAddress.expiryDate) > new Date();

        return isActive && isNotExpired;
    });

export const makeProxyAddressOptions = (proxyAddresses: ProxyAddress[]): InputOptions[] =>
    proxyAddresses.map((proxyAddress) => ({
        label: getProxyAddressDetailsTitle(proxyAddress),
        value: proxyAddress.proxyAddressUuid,
    }));
