import { ICellRendererParams } from '@ag-grid-community/core';
import React from 'react';
import { ApplicantWithStaffDetails, CouncilAreaOutput, Org, ProxyAddress, StaffUser } from 'proxyaddress-common/types';
import { isNil, keys } from 'ramda';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../components/bits/Buttons/Button';
import Initials from '../components/bits/Grid/Initials';
import StatusBadge from '../components/bits/Grid/StatusBadge';
import { formatGridDate, getApplicationStatusText } from './application';
import { formatProxyAddressDates, formatProxyAddressSource } from './proxyAddress';
import { getRouteCouncilAreaDetail } from './routes';
import { getStaffInitials } from './staffDashboard';

export const GridWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(95vh - 100px);
    width: 100%;
`;

export const obscureName = (name: string, fullyObscure?: boolean) => {
    const obscuredNameArr = name.split('').map((char, index) => {
        if (fullyObscure) return '*';
        const prevChar = name[index - 1];
        if (/[@ _-]/.test(prevChar)) return char;
        if (index !== 0 && !/[@ -._]/.test(char)) return '*';
        else return char;
    });

    return obscuredNameArr.join('');
};

interface StaffCellRendererProps {
    applicantData: Record<string, ApplicantWithStaffDetails>;
    redirectToApplicant: (applicant: ApplicantWithStaffDetails, applicantUuid: string) => void;
    orgsData?: Record<string, Org | undefined>;
    redirectToOrg?: (org: Org | undefined, orgUuid: string) => void;
}

interface OrgCellRendererProps {
    orgsData: Record<string, Org | undefined>;
    redirectToOrg: (org: Org | undefined, orgUuid: string) => void;
    councilAreas: Record<string, CouncilAreaOutput>;
}

interface AdminAllStaffProps {
    staffData: Record<string, StaffUser>;
    orgsData: Record<string, Org | undefined>;
    redirectToOrg: (org: Org | undefined, orgUuid: string) => void;
}

interface AdminProxyAddressesProps {
    councilAreas: Record<string, CouncilAreaOutput>;
    redirectToProxyAddress: (proxyAddress: ProxyAddress, proxyAddressUuid: string) => void;
}

const modifiedAtFunc = ({ data }: ICellRendererParams) => {
    const { modifiedAt } = data;
    if (isNil(modifiedAt)) return '-';
    else return formatGridDate(modifiedAt);
};

const isActiveRenderer = ({ data }: ICellRendererParams) => {
    const { isActive } = data;
    if (isActive) return <StatusBadge text="ACTIVE" />;
    else return <StatusBadge text="INACTIVE" />;
};

const statusRenderer = ({ data }: ICellRendererParams) => {
    const { status } = data;
    return <StatusBadge text={status} />;
};

export const getStaffDashboardCellRenderers = ({
    applicantData,
    redirectToApplicant,
    orgsData,
    redirectToOrg,
}: StaffCellRendererProps) => {
    return {
        applicantLinkRenderer: ({ rowIndex, value }: ICellRendererParams) => {
            const applicantUuid = keys(applicantData)[rowIndex];
            const applicant = applicantData[applicantUuid];
            return (
                <Button
                    buttonStyle="gridLink"
                    text={value}
                    onClick={() => redirectToApplicant(applicant, applicantUuid)}
                />
            );
        },
        obscureNamesRenderer: ({ rowIndex, value }: ICellRendererParams) => {
            const applicantUuid = keys(applicantData)[rowIndex];
            const applicant = applicantData[applicantUuid];
            const hiddenName = obscureName(value);
            return (
                <Button
                    buttonStyle="gridLink"
                    text={hiddenName}
                    onClick={() => redirectToApplicant(applicant, applicantUuid)}
                />
            );
        },
        orgLinkRenderer: ({ data }: ICellRendererParams) => {
            if (!orgsData || !redirectToOrg) return null;
            const { orgUuid } = data;
            const org = orgsData[orgUuid];
            return <Button buttonStyle="gridLink" text={org?.name} onClick={() => redirectToOrg(org, orgUuid)} />;
        },
        pinRenderer: ({ data }: ICellRendererParams) => {
            const { pin } = data;
            if (isNil(pin)) return '  -';
            else return pin;
        },
        lastUpdatedRenderer: modifiedAtFunc,
        statusRenderer: ({ data }: ICellRendererParams) => {
            const { applicationStatus } = data;
            const applicationStatusText = getApplicationStatusText(applicationStatus);
            return <StatusBadge text={applicationStatusText} />;
        },
        staffRenderer: ({ data }: ICellRendererParams) => {
            const { staffMember } = data;
            const initials = getStaffInitials(staffMember?.name || 'Admin');
            return <Initials initials={initials} />;
        },
    };
};

export const getOrgAdminCellRenderers = ({ orgsData, redirectToOrg, councilAreas }: OrgCellRendererProps) => {
    return {
        orgLinkRenderer: ({ data, value }: ICellRendererParams) => {
            const { orgUuid } = data;
            const org = orgsData[orgUuid];
            return <Button buttonStyle="gridLink" text={value} onClick={() => redirectToOrg(org, orgUuid)} />;
        },
        statusRenderer,
        mainContactRenderer: ({ value }: ICellRendererParams) => {
            if (value === '') return '-';
            else return value;
        },
        councilAreaRenderer: ({ data }: ICellRendererParams) => {
            const councilAreaUuid = data.councilAreaUuids[0];
            return councilAreas[councilAreaUuid]?.name || '-';
        },
    };
};

export const getAdminAllStaffCellRenderers = ({ orgsData, redirectToOrg }: AdminAllStaffProps) => {
    return {
        orgLinkRenderer: ({ data }: ICellRendererParams) => {
            const { orgUuid } = data;
            const org = orgsData[orgUuid];
            if (org)
                return <Button buttonStyle="gridLink" text={org.name} onClick={() => redirectToOrg(org, orgUuid)} />;
            else return '-';
        },
        createdAtRenderer: ({ data }: ICellRendererParams) => {
            const { createdAt } = data;
            return formatGridDate(createdAt);
        },
        modifiedAtRenderer: modifiedAtFunc,
        isActiveRenderer,
    };
};

export const getCouncilAreasCellRenderers = () => {
    return {
        councilAreaLinkRenderer: ({ data }: ICellRendererParams) => {
            const { councilAreaUuid, name } = data;
            return <Link to={getRouteCouncilAreaDetail(councilAreaUuid)}>{name}</Link>;
        },
        statusRenderer,
        councilStatusRenderer: ({ value }: ICellRendererParams) => {
            return <StatusBadge text={value} />;
        },
    };
};

export const getOrgDetailsRenderers = () => {
    return {
        statusRenderer: isActiveRenderer,
        createdAtRenderer: ({ data }: ICellRendererParams) => {
            const { createdAt } = data;
            return formatGridDate(createdAt);
        },
    };
};

export const getProxyAddressesCellRenderers = ({ councilAreas, redirectToProxyAddress }: AdminProxyAddressesProps) => {
    return {
        displayAddressRenderer: ({ data: proxyAddress }: ICellRendererParams) => {
            const { proxyAddressUuid } = proxyAddress;
            return (
                <Button
                    buttonStyle="gridLink"
                    text={proxyAddress.displayAddress}
                    onClick={() => redirectToProxyAddress(proxyAddress, proxyAddressUuid)}
                />
            );
        },
        statusRenderer: ({ data }: ICellRendererParams) => {
            const { isActive } = data;
            return <StatusBadge text={isActive ? 'ACTIVE' : 'INACTIVE'} />;
        },
        councilAreaRenderer: ({ data }: ICellRendererParams) => {
            const { councilAreaUuid } = data;
            if (!councilAreas) return null;
            return councilAreas[councilAreaUuid]?.name || '-';
        },
        councilAreaValueGetter: ({ data }: ICellRendererParams) => {
            const { councilAreaUuid } = data;
            if (!councilAreas) return null;
            return councilAreas[councilAreaUuid]?.name || '-';
        },
        sourceRenderer: ({ data }: ICellRendererParams) => {
            const { source } = data;
            return formatProxyAddressSource(source);
        },
        expiryDateRenderer: ({ data }: ICellRendererParams) => {
            const { expiryDate } = data;
            return formatProxyAddressDates(expiryDate);
        },
        activationDateRenderer: ({ data }: ICellRendererParams) => {
            const { activationDate } = data;
            return formatProxyAddressDates(activationDate);
        },
    };
};
