import { GridApi } from '@ag-grid-community/core';
import { values } from 'ramda';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { getProxyAddressesCellRenderers, GridWrapper } from '../../utils/grid';
import Button from '../bits/Buttons/Button';
import Grid from '../bits/Grid/Grid';
import { getProxyAddressColumns } from '../bits/Grid/gridOptions';
import { AdminStateContext } from '../WithAdminState/adminState';
import DashboardButtonSearch from './DashboardButtonSearch';
import { StaffUserContext } from '../WithStaffUser/staffContext';
import AuthContext from '../WithAuth/AuthContext';
import { getRouteProxyAddressNew } from '../../utils/routes';
import { useParams } from 'react-router-dom';

interface ProxyAddressAdminProps {
    councilAreaUuid?: string;
}

const ProxyAddressAdmin = ({ councilAreaUuid: councilAreaUuidParam }: ProxyAddressAdminProps) => {
    const history = useHistory();
    const { isAdmin } = useContext(AuthContext);
    const {
        proxyAddresses: adminProxyAddresses,
        councilAreas,
        redirectToProxyAddress: adminRedirect,
    } = useContext(AdminStateContext);
    const { orgUuid }: { orgUuid: string } = useParams();
    const {
        proxyAddresses: staffProxyAddresses,
        redirectToProxyAddress: staffRedirect,
        organisation,
    } = useContext(StaffUserContext);
    const proxyAddressesRecord = isAdmin ? adminProxyAddresses : staffProxyAddresses;
    const redirectToProxyAddress = isAdmin ? adminRedirect : staffRedirect;
    const councilAreaUuid = councilAreaUuidParam || organisation?.councilAreaUuids[0];

    const proxyAddresses = values(proxyAddressesRecord);
    const filteredProxyAddresses = councilAreaUuid
        ? proxyAddresses.filter((proxyAddress) => proxyAddress.councilAreaUuid === councilAreaUuid)
        : proxyAddresses;

    const [gridApi, setGridApi] = useState<GridApi | undefined>();

    const createButton = (
        <Button
            buttonStyle="primary"
            text="+ New ProxyAddress"
            onClick={() => {
                history.push(getRouteProxyAddressNew(councilAreaUuid || '', orgUuid || organisation?.orgUuid || ''));
            }}
        />
    );

    return (
        <GridWrapper>
            <DashboardButtonSearch title="ProxyAddresses" body={!!councilAreaUuid ? createButton : undefined} />
            <Grid
                gridApi={gridApi}
                rowData={filteredProxyAddresses}
                setGridApi={setGridApi}
                columns={getProxyAddressColumns(isAdmin && !councilAreaUuid, councilAreas)}
                frameworkComponent={{ ...getProxyAddressesCellRenderers({ councilAreas, redirectToProxyAddress }) }}
            />
        </GridWrapper>
    );
};

export default ProxyAddressAdmin;
